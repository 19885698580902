/* ----- Variables Générales ----- */
:root {
    --police-principale: Arial, sans-serif;   /* Police d'écriture */
    --couleur-texte: rgb(73, 228, 26);                   /* Couleur du texte */
    --style-texte: normal;                    /* Style du texte (normal, italic, etc.) */
    --espacement-texte: 20px;                 /* Espacement entre les paragraphes */
    --espacement-image-texte: 2px;           /* Espacement entre l'image et le texte */
}

/* ----- Général ----- */
.management-page {
    font-family: var(--police-principale);
    padding: 1px;
    background-color: white;
    box-sizing: border-box;
    --text-spacing: var(--espacement-texte); 
}

/* ----- Section Arrière-plan ----- */
.background-section-Management {
    width: 100%;
    max-height: 800px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--couleur-texte);
    padding: 200px 0;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    height: 460px;
}

.background-section-Management::before {
    content: '';
    background: url('image3.webp') no-repeat center/cover;
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.7;
}

.overlay-text {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    background-color: #615649;
    text-align: center;
    max-width: 80%;
    z-index: 2;
    font-style: var(--style-texte);
    margin-left: -500px;
    margin-bottom: 500px;
    border-radius: 60px;
    
}

.overlay-text h1 {
    margin-bottom: var(--text-spacing);
    font-size: 52px;
    
}

.overlay-text h2 {
    margin-bottom: var(--text-spacing);
    font-size: 24px;
}

/* ----- Section Rectangle ----- */
.rectangle03 {
    height: 400px;
    border: 6px solid white;
    width: 1000px;
    margin-left: 40px;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--text-spacing);
    margin-bottom: 40px;
   padding: 5px;
    overflow: hidden;
    text-align: center;
    border-radius: 85px;
    transition: transform 0.3s ease-in-out;
    gap: var(--espacement-image-texte); /* Espace entre l'image et le texte */
}

.image-container {
    position: relative;
    
    flex: 2;
    margin: 90px;
    max-width: 400px;
    overflow: hidden;
    width: 100%; /* Pleine largeur pour les petits écrans */
    height: 100%;
    margin-bottom: 15px; /* Ajuster l'espacement du bas */
}

.image-container img {
    width: 100%;
    height: 100%;
    max-height: 300px;
    display: block;
    object-fit: cover; /* Ajouter ceci */
}

.text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.column {
    flex: 1;
}

.column:first-child {
    margin-bottom: var(--text-spacing);
}

.column h3, .column p {
    color: var(--couleur-texte);
    font-style: var(--style-texte);
    word-wrap: break-word;
}

.column h3 {
    padding-bottom: var(--text-spacing);
}

.column p {
    text-align: justify;
}

/* ----- Section 4 ----- */
.section-4 {
    background-color: #fefeff;
    padding: 80px;
    border-radius: 85px;
    text-align: left;
    height: 200px;
    border: 6px solid white;
   
    margin-bottom: var(--text-spacing);
    overflow: auto; 
    width: 900px;
}

.section-4 h1 {
    margin-bottom: 5px;
    word-wrap: break-word;
    color: var(--couleur-texte);
    font-style: var(--style-texte);
}
.section-4 p {
    margin-bottom: 5px;
    word-wrap: break-word;
    color: var(--couleur-texte);
    font-style: var(--style-texte);
}

/* ----- Media Query pour la taille mobile ----- */
@media only screen and (max-width: 760px) {
    .rectangle03 {
        height: auto;
        gap: 10px;
        flex-direction: column;
        width: auto; /* Largeur auto pour s'adapter à l'écran */
        margin-left: 0; /* Supprimer la marge gauche fixe */
        padding: 20px; /* Réduire le padding pour petits écrans */
        border-radius: 50px; /* Ajuster le rayon pour les petits écrans */
    }

    @media only screen and (max-width: 760px) {
        .section-4 {
            height: auto;
            gap: 10px;
            flex-direction: column;
            width: auto; /* Largeur auto pour s'adapter à l'écran */
            margin-left: 0; /* Supprimer la marge gauche fixe */
            padding: 20px; /* Réduire le padding pour petits écrans */
            border-radius: 50px; /* Ajuster le rayon pour les petits écrans */
        }
 
    .image-container, .text-container {
        width: 100%;
        margin-bottom: var(--text-spacing);
    }
}
}