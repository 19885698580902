.portfolio-page {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

/* Styles de base pour chaque section */
.section01, .section02, .section03, .section005 {
  padding: 50px 0;
}

.dynamic-text {
  color: whitesmoke; 
  background-color: rgb(14, 9, 9);
  font-size: 22px;
  margin-top: -600px;
}

/* Section 1 */
.section01 {
  background-image: url('Background1.webp');
  background-size: cover;
  height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Section 2 */
.section02 {
  background-color: rgb(238, 238, 238);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 300px;
  overflow: hidden;
}

.rectangle-container {
  background-color: (238, 238, 238);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  height: auto;
}

.rectangle {
  background-color: rgb(14, 9, 9) !important;
  border: none !important;
  border-radius: 50px !important;
  width: 290px;
  height: auto;
  flex-direction: column;
  margin: 10px;
  transition: transform 0.3s ease;
  
}

.rectangle h2 {
  color: goldenrod;
  margin-left: 30px;
  margin-bottom: 10px;
  font-size: 1.2em;
  overflow: hidden;
  line-height: 1.4;
}

.rectangle p {
  color: #0fa6ec;
  margin-top: 0;
  font-size: 0.9em;
  line-height: 1.8;
}

/* Section 3 */
.section03 {
  display: flex;
  justify-content: space-between;
  background-color: rgb(14, 9, 9);
  align-items: center;
  padding: 20px 0;
  overflow: hidden;
}

.text-area {
  flex: 1;
  margin-left: 60px;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
}

.text-area h2 {
  text-align: center;
  font-size: 1.2;
  color: gold;
  margin-bottom: 15px;
  margin-top: 0;
  line-height: 1.2;
}

.text-area p {
  color: #0fa6ec;
  font-size: 1.2;
  line-height: 1.6;
  text-align: center;
  width: 100%;
}

.section003 {
  background-color: rgb(207, 204, 204);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  overflow: hidden;
  margin-left: 30px;
  text-align: Left;
  margin: 20px;
  
}

.text-area003 {
  position: right;
  background-color: rgb(207, 204, 204);
  color: black;
  text-align: left;
  width: 270px;
  height: 180px;
  margin-right: 180px;
}

.image-area {
  flex: 1;
  max-width: 50%;
  max-height: 400px;
  height: auto;
}

.Test-page {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

/* Section 5 */
.section005 {
  display: flex;
  overflow: hidden;
  padding: 20px;
  position: relative;
}

.carousel-container1 {
  display: flex;
  scroll-snap-type: x mandatory;
  width: 3200px;
  
}

.carousel-item1 {
  scroll-snap-align: start;
  margin: 15px;
  background-color: rgb(14, 9, 9) !important;
  color: goldenrod;
  border-radius: 20px;
  padding: 20px;
  width: 1300px !important;
  height: 650px !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.carousel-item1 p {
color: #0fa6ec;
line-height: 1.4;
font-size: 1em;
}

.carousel-item1 img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  height: 250px;
  overflow: hidden;
}
.text-caroussel {
  color: black;
  text-align: center;
}

.carousel-arrow {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-arrow.left1 {
  background-color: gold !important;
  left: 10px;
  color:  rgb(14, 9, 9) !important;
  height: 40px !important;
  width: 60px;
}

.carousel-arrow.right1 {
  background-color:gold !important;
  right: 10px;
  color: rgb(14, 9, 9) !important;
  height: 40px !important;
  width: 60px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .section01, .section02, .section03,.section003, .section005 {
      padding: 10px;
      margin: 0 auto;
      flex-direction: column;
      margin: 10px;
      justify-content: center; 
      align-items: center;
      overflow: hidden;
      
      
  }
}

@media (min-width: 2000px) {
  .section01, .section02, .section03, .section04, .section05 {
    padding: 40px;
    margin: 30px;
    flex-direction: row ;
    justify-content: center; 
      align-items: center;
  }
}

.section02 .rectangle:hover {
  transform: scale(1.05);
  cursor: pointer;
}

/* Section 5: Carrousel */
/* Additional styles for carousel as needed */

/* Responsive Design for Carousel */
.carousel-item1 {
  width: 100%;
}
@media (max-width: 480px) {
  .rectangle {
    width: calc(100% - 20px); /* Prend encore moins de marge */
  }
}


