/* Variables globales pour les styles */
:root {
    --couleur-texte: rgb(0, 0, 0) !important;
    --couleur-titre: rgb(14, 9, 9);
    --taille-texte: 19px;
    --taille-titre: 28px;
    --align-texte: left;
    --espace-titre-texte: 0px;  /* Nouvelle variable pour l'espacement entre les titres et le texte */
}

/* Styles pour la page d acceuill */
.service-page {
    font-family: Arial, sans-serif;
    padding: 0px;
    background-color: rgb(14, 9, 9);
    box-sizing: border-box;
    background-size: cover;

}

/* Espace entre les sections */
.section-service {
    margin-bottom: 50px;
}

/* Image de fond pour certaines sections */
.background-section-services {
    height: 70vh;
    background: url('image2.webp') no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 1px;
    height: 810px;
}

/* Texte au-dessus de l'image de fond */
.overlay-text1 h1, .overlay-text1 p {
    text-align: center;
    background-color: black;
    margin-left: 150px;
    margin-top: -200px;
    margin-bottom: var(--espace-titre-texte); 
}

/* Sections avec image et texte côte à côte */
.rectangle02{
    display: flex;
    gap: 20px;
    background-color: rgb(244, 240, 240);
    color: black;
    padding: 10px;
    border-radius: 70px;
    width: 1000px;
    margin-left: 50px;
    
   
}

/* Inversement du texte et de l'image */
.rectangle02.reversed {
    flex-direction: row;
    margin-left: 50px;
    
}

/* Styles pour les conteneurs de texte et d'image */
.text-container, .image-container {
    flex-basis: 50%;
}

/* Styles pour les images dans les sections rectangle */
.image-container img {
    width: 100%;
    height: auto;
    margin-left: 50px;
}

/* Section avec trois boîtes côte à côte */
.white-section-service {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    overflow-y: auto;
}

/* Styles pour chaque boîte dans la section blanche */
.box {
    flex-basis: calc(33.333% - 20px);
    background-color: rgb(247, 245, 245);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 75px;
    margin: 40px;
    border-radius: 70px;
}
 /*Modifier l'écart entre dans les 3 rectangles côtes à côte et le responsive design*/
/* Styles généraux pour les textes et titres */
.texte {
    color: var(--couleur-texte);
    font-size: var(--taille-texte);
    text-align: var(--align-texte);
    margin-bottom: 2px; /*modifie l ecart entre la section a rois rectangle et celui du haut*/
    
}

.titre1 {
    margin-left: 50px;
    color: black;
    font-size: var(--taille-titre);
    text-align: var(--align-texte);
    margin-bottom: 2px; 
    line-height: 1.4;
    
}
.titre2 {
    margin-left: 50px;
    color: black;
    font-size: var(--taille-titre);
    text-align: var(--align-texte);
    margin-bottom: -60px; 
}
.titre3 {
    margin-left: 50px;
    color: black;
    font-size: var(--taille-titre);
    text-align: var(--align-texte);
    margin-bottom: -60px; 
}
.titre4 {
    margin-left: 50px;
    color: black;
    font-size: var(--taille-titre);
    text-align: var(--align-texte);
    margin-bottom: -60px; 
}
.titre5 {
    margin-left: 51px;
    color: black;
    font-size: var(--taille-titre);
    text-align: var(--align-texte);
    margin-bottom: 2px; 
}

/* Styles responsive pour les écrans plus petits */
@media (max-width: 768px) {
    .background-section-service {
        height: 40vh; /* Ajuster la hauteur pour les petits écrans */
    }

    .rectangle02 {
        width: auto; /* Largeur automatique */
        margin: 0 15px; /* Marge pour la visibilité */
        flex-direction: column;
        gap: 10px;
    }

    .overlay-text h1 {
        font-size: 1.5rem;
    }
    
    .white-section-service {
        flex-direction: column;
    }
    
    .box {
        flex-basis: 100%;
        margin: 20px 0;
    }
    
    .rectangle02.reversed {
        flex-direction: column;
    }
    
    .texte, .titre {
        text-align: left; /* Alignement du texte pour les petits écrans */
    }
}

/* Responsive pour très petits écrans */
@media (max-width: 480px) {
    .rectangle02 {
        margin: 0 10px; /* Marge réduite */
    }
    .white-section-service {
        flex-direction: row;
        justify-content: space-between;
    }
    .box {
        margin: 10px 0;
        width: auto;
        flex-basis: calc(33.333% - 20px); /* adjust the 20px if you have a different gap value */
        margin: 10px;
    }

    .titre, .texte {
        font-size: smaller; /* Taille de police réduite pour les très petits écrans */
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
   .service-page{
    padding: 20px;
   }
    /* Styles spécifiques pour les tablettes et les écrans d'une certaine taille */
}
.rectangle02{
    padding: 15px;
    gap: 15px;
}
.box{
    flex-basis:calc(50% - 20px) ;
    margin:10px;
}
@media (min-width: 1025px) {
    .service-page {
        /* You might want to max out the width of the content so it doesn't stretch too wide on large screens */
        max-width: 1200px; /* or whatever maximum width you find appropriate */
        margin: auto; /* This will center the content if there's a max-width */
    }

    .rectangle02 {
        /* On larger screens, you can afford more padding and larger gaps */
        padding: 20px;
        gap: 25px;
    }

   

    .texte {
        /* Adjust the font size for large screens if necessary */
        font-size: calc(var(--taille-texte) + 2px);
    }

    .titre {
        /* Adjust the title font size for large screens if necessary */
        font-size: calc(var(--taille-titre) + 4px);
    }

    /* For larger screens, you can display the boxes in the white-section-service side by side */
    .white-section-service {
        justify-content: flex-start;
    }

    .box {
        /* On large screens, the boxes can be a bit more spaced out */
        flex-basis: calc(33.333% - 40px); /* Adjusting for the gap */
        margin: 20px;
    }

    /* You can also introduce hover effects for desktops as they have mouse input */
    .box:hover {
        /* Some hover effect */
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    }

    /* Any other large screen adjustments */
}

