.app-header {
  background-color: #000;
  padding: 1px 0;
  color: #0fa6ec;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo-image {
  height: 120px;
  width: auto;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px;
}

nav ul li {
  position: relative;
}

nav ul li a, nav ul li span {
  color:#0fa6ec;
  display: inline-block;
  padding: 10px 25px;
  transition: transform 0.3s, background-color 0.3s;
  text-decoration: none;
  font-size: 20px;
}

nav ul li a:hover, nav ul li span:hover {
  background-color: #333;
}

nav ul .dropdown {
  display: none;
  position: absolute;
  background: #000;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 10px;
 
  top: 100%;

  z-index: 1;
}

nav ul li:hover .dropdown {
  display: block;
}

nav ul .dropdown.show {
  display: block;
}

.menu-toggle {
  display: none;
  background: #000;
  border: none;
  color: #0fa6ec;
  font-size: 34px;
  cursor: pointer;
}

.menu-toggle.open {
  display: none;
}

@media only screen and (max-width: 768px) {
  nav {
      display: none;
      width: 100%;
  }

  nav.active {
      display: block;
  }

  nav ul {
      flex-direction: column;
      gap: 10px;
      align-items: flex-end;
  }

  nav ul .dropdown {
      position: relative;
      top: auto;
      left: auto;
      background: transparent;
      border: none;
  }

  .menu-toggle {
      display: block;
  }
}
